@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
	font-family: 'Jost';
	src: url('/fonts/Jost-VariableFont_wght.ttf');
	font-weight: 400 500;
  font-display: swap;
}
